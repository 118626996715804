import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";
import StyledModal from "./StyledModal";
import StyledButton from "./StyledButton";

const StyledNotification = (props) => {
    const {
        open,
        onClose,
        renderNotification,
        cancelText,
        cancelAction,
        acceptText,
        acceptAction,
        className,
        brandColorOverride,
    } = props;

    const notification = renderNotification();
    const cancelMessage = cancelText || "Cancel";
    const okMessage = acceptText || "OK";

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            className={`styled-notification ${className}`}
        >
            {notification}
            <div className="styled-notification-btns">
                {!!cancelAction
                    ? <StyledButton
                        type="secondary"
                        onClick={cancelAction}
                        brandColorOverride={brandColorOverride}
                    >
                        {cancelMessage}
                    </StyledButton>
                    : null
                }
                {!!acceptAction
                    ? <StyledButton
                        type="main"
                        onClick={acceptAction}
                        brandColorOverride={brandColorOverride}
                    >
                        {okMessage}
                    </StyledButton>
                    : null
                }
            </div>
        </StyledModal>
    );
};

export default StyledNotification;