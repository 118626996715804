import React, {useEffect, useRef} from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledCheckbox = (props) => {
    const checkboxRef = useRef();
    const {
        className,
        checked,
        onChange,
        disabled,
        hasError,
        indeterminate,
        label,
        brandColorOverride,
    } = props;


    useEffect(() => {
        checkboxRef.current.indeterminate = !!indeterminate;
    }, [checkboxRef, indeterminate]);

    return (
        <div className={`styled-checkbox-holder small-text-regular ${!!disabled ? " disabled" : ""}${indeterminate ? " indeterminate" : ""}${hasError ? " error" : ""}${className ? " " + className : ""}`}>
            <label
                className={`checkbox`}
            >
                <input
                    style={!!brandColorOverride && checked ? {backgroundColor: brandColorOverride, borderColor: brandColorOverride} : null}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    ref={checkboxRef}
                />
                <span>{label}</span>
                <span className="checkmark"/>
            </label>

        </div>
    );
};

export default StyledCheckbox;