import React from "react";
import LoaderModal from "./LoaderModal";
import VideoNudgeModal from "./VideoNudgeModal";
import ImagesRejectedNotification from "./ImagesRejectedNotification";
import UploadMediaModal from "./UploadMediaModal";

const ModalContainer = () => {
    return (
        <>
            <LoaderModal/>
            <VideoNudgeModal/>
            <ImagesRejectedNotification/>
            <UploadMediaModal/>
        </>
    )
}

export default ModalContainer;