import React, {useContext} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {ModalContext} from "../../contexts/ModalContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import StyledNotification from "../../shared-components/generic/StyledNotification";

const VideoNudgeModal = () => {
    const {
        appLang,
        setHasBeenAskedToShareVideo,
        goToShareMore,
        goBackToKeepSnapping,
    } = useContext(GeneralContext)
    const {videoNudgeModalOpen, setVideoNudgeModalOpen} = useContext(ModalContext);
    const content = language[appLang];

    const handleClose = () => {
        setHasBeenAskedToShareVideo(true);
        setVideoNudgeModalOpen(false);
    }

    const handleContinueAnyway = () => {
        goToShareMore();
        handleClose();
    }

    const handleGoBack = () => {
        goBackToKeepSnapping();
        handleClose();
    }

    const renderText = () => {
        return (
            <div className="additional-text video-nudge-text">
                <span>{Utility.capitalize(content.WED_LOVE_YOUR_HELP)}</span>
                <span>{Utility.capitalize(content.ENCOURAGE_SHARE_VIDEO_STATEMENT)}</span>
            </div>
        )
    }

    return (
        <StyledNotification
            open={videoNudgeModalOpen}
            onClose={handleClose}
            className="video-nudge-modal"
            renderNotification={renderText}
            cancelText={Utility.capitalize(content.NEXT_TIME)}
            cancelAction={handleContinueAnyway}
            acceptText={Utility.capitalize(content.GOT_IT)}
            acceptAction={handleGoBack}
        />
    )
}

export default VideoNudgeModal;