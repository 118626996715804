import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledInput = (props) => {
    const {
        className,
        onChange,
        onKeyDown,
        value,
        placeholder,
        header,
        headerTextClass,
        errorMessage,
        hasError,
        initialRows,
    } = props;

    return (
        <div className={`styled-input${className ? " " + className : ""}`}>
            {!!header
                ? <div className={`input-header${headerTextClass ? " " + headerTextClass : ""}`}>
                    {header}
                </div>
                : null
            }
            <textarea
                className={`additional-text${hasError ? "has-error" : ""}`}
                value={value || ""}
                onChange={onChange}
                placeholder={placeholder || ""}
                rows={initialRows || 1}
                onKeyDown={onKeyDown}
            />
            {hasError
                ? <div className="error-message additional-text">
                    {errorMessage}
                </div>
                : null
            }
        </div>
    );
};

export default StyledInput;