import React from "react";

const NotFound = () => {
    return (
        <div className="main-container">
            <div className="main">
                404 - Page not found
            </div>
        </div>
    );
};

export default NotFound;