import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledList = (props) => {
    const {numbered, items, className} = props;
    const ListTag = numbered ? "ol" : "ul";

    return (
        <ListTag className={`${className ? className + " " : ""}styled-list`}>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ListTag>
    );
};

export default StyledList;