import React, {useContext, useEffect} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import thankYouMedia from "../assets/thank-you-media.png";
import StyledButton from "../shared-components/generic/StyledButton";

const ThankYouPage = () => {
    const {
        appLang,
        thankYouDts,
        setThankYouDts,
        goToViewMyShares,
    } = useContext(GeneralContext);
    const content = language[appLang];

    useEffect(() => {
        setThankYouDts(new Date());
    }, []);

    return (
        <div className="thank-you-page">
            <div className="thank-you-date additional-text">
                {Utility.renderDate_MMDDYYY(thankYouDts)}
            </div>
            <img
                className="thank-you-media"
                src={thankYouMedia}
                alt={content.IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA}
            />
            <div className="main-container">
                <div className="big-text">
                    <span>{Utility.capitalize(content.GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US)}</span>
                </div>
                <div className="btn-container">
                    <StyledButton
                        type="main"
                        onClick={goToViewMyShares}
                    >
                        {Utility.capitalize(content.NEXT)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;