import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";
import {Alert, Snackbar} from "@mui/material";

const StyledSnackbar = (props) => {
    const {
        children,
        className,
        open,
        onClose,
        autoHideDuration,
        message,
        type,   //"error", "warning", "success", "info"
    } = props;

    // const renderIcon = () => {
    //     switch (type) {
    //         case "error":
    //             return
    //     }
    // }

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            className="styled-snackbar"
        >
            <Alert
                severity={type}
                onClose={onClose}
                variant="outlined"
                className={className}
            >
                {message}
                {children}
            </Alert>
            {/*<div className={`styled-snackbar ${className}`}>*/}
            {/*    <div className="side-slash"/>*/}

            {/*</div>*/}
        </Snackbar>
    );
};

export default StyledSnackbar;