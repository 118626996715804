import React from 'react';
import settings from "../settings.json";

const {contactEmail} = settings;

const TermsAndConditions = () => {

    return (
        <div className="main-container with-header">
            <div className="h2">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</div>
            <div className="terms-container">
                <div className="h3">Who we are and how to contact us</div>
                <div className="additional-text">This User-Generated Content (UGC) platform ("Platform") is operated by Scale Social AI, LLC ("We" or "Us"). We are registered in the United States under the laws of North Carolina.</div>
                <div className="additional-text">To contact us, please email {contactEmail}</div>
            </div>
            <div className="terms-container">
                <div className="h3">By using our Platform you accept these terms</div>
                <div className="additional-text">By using our Platform, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our Platform.</div>
                <div className="additional-text">We recommend that you print a copy of these terms for future reference.</div>
            </div>
            <div className="terms-container">
                <div className="h3">There are other terms that may apply to you</div>
                <div className="additional-text">These terms of use refer to the following additional terms, which also apply to your use of our Platform:</div>
                <ul>
                    <li className="additional-text">Our <a href="https://www.getsocialscale.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, which sets out the terms on which we process any personal data we collect from you, or that you provide to us.</li>
                    <li className="additional-text">Our <a href="https://www.getsocialscale.com/privacy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>, which sets out information about the cookies on our Platform.</li>
                </ul>
            </div>
            <div className="terms-container">
                <div className="h3">We may make changes to these terms</div>
                <div className="additional-text">We amend these terms from time to time. Every time you wish to use our Platform, please check these terms to ensure you understand the terms that apply at that time.</div>
            </div>
            <div className="terms-container">
                <div className="h3">We may make changes to our Platform</div>
                <div className="additional-text">We may update and change our Platform from time to time to reflect changes to our services, our users’ needs, and our business priorities.</div>
            </div>
            <div className="terms-container">
                <div className="h3">We may suspend or withdraw our Platform</div>
                <div className="additional-text">Our Platform is made available free of charge.</div>
                <div className="additional-text">We do not guarantee that our Platform, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Platform for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</div>
                <div className="additional-text">You are also responsible for ensuring that all persons who access our Platform through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</div>
            </div>
            <div className="terms-container">
                <div className="h3">Our Platform is only for users in the U.S.</div>
                <div className="additional-text">Our Platform is directed to people residing in the United States. We do not represent that content available on or through our Platform is appropriate for use or available in other locations.</div>
            </div>
            <div className="terms-container">
                <div className="h3">You must keep your account details safe</div>
                <div className="additional-text">If you choose, or you are provided with, a user identification code, password, or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</div>
                <div className="additional-text">We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</div>
                <div className="additional-text">If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at {contactEmail}.</div>
            </div>
            <div className="terms-container">
                <div className="h3">How you may use material on our Platform</div>
                <div className="additional-text">We are the owner or the licensee of all intellectual property rights in our Platform, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</div>
                <div className="additional-text">You may print off one copy, and may download extracts, of any page(s) from our Platform for your personal use and you may draw the attention of others within your organization to content posted on our Platform.</div>
                <div className="additional-text">You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video, or audio sequences or any graphics separately from any accompanying text.</div>
                <div className="additional-text">Our status (and that of any identified contributors) as the authors of content on our Platform must always be acknowledged.</div>
                <div className="additional-text">You must not use any part of the content on our Platform for commercial purposes without obtaining a license to do so from us or our licensors.</div>
                <div className="additional-text">If you print off, copy, or download any part of our Platform in breach of these terms of use, your right to use our Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</div>
            </div>
            <div className="terms-container">
                <div className="h3">Do not rely on information on this Platform</div>
                <div className="additional-text">The content on our Platform is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Platform.</div>
                <div className="additional-text">Although we make reasonable efforts to update the information on our Platform, we make no representations, warranties, or guarantees, whether expressed or implied, that the content on our Platform is accurate, complete, or up to date.</div>
            </div>
            <div className="terms-container">
                <div className="h3">We are not responsible for websites we link to</div>
                <div className="additional-text">Where our Platform contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</div>
                <div className="additional-text">We have no control over the contents of those sites or resources.</div>
            </div>
            <div className="terms-container">
                <div className="h3">Our responsibility for loss or damage suffered by you</div>
                <div className="h4">Whether you are a consumer or a business user:</div>
                <div className="additional-text">We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents, or subcontractors and for fraud or fraudulent misrepresentation.</div>
                <div className="h4">If you are a business user:</div>
                <div className="additional-text">We exclude all implied conditions, warranties, representations, or other terms that may apply to our Platform or any content on it.</div>
                <div className="additional-text">We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</div>
                <ul>
                    <li className="additional-text">use of, or inability to use, our Platform; or</li>
                    <li className="additional-text">use of or reliance on any content displayed on our Platform.</li>
                </ul>
                <div className="additional-text">In particular, we will not be liable for:</div>
                <ul>
                    <li className="additional-text">loss of profits, sales, business, or revenue;</li>
                    <li className="additional-text">business interruption;</li>
                    <li className="additional-text">loss of anticipated savings;</li>
                    <li className="additional-text">loss of business opportunity, goodwill, or reputation; or</li>
                    <li className="additional-text">any indirect or consequential loss or damage.</li>
                </ul>
                <div className="h4">If you are a consumer user:</div>
                <div className="additional-text">Please note that we only provide our Platform for domestic and private use. You agree not to use our Platform for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</div>
                <div className="additional-text">If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</div>
            </div>
            <div className="terms-container">
                <div className="h3">How we may use your personal information</div>
                <div className="additional-text">We will only use your personal information as set out in our Privacy Policy.</div>
            </div>
            <div className="terms-container">
                <div className="h3">User-Generated Content (UGC)</div>
                <div className="h4">By submitting content (including but not limited to photos, videos, reviews, and comments) to our Platform, you:</div>
                <ol>
                    <li className="additional-text">Grant us and our business clients a non-exclusive, perpetual, irrevocable, royalty-free, transferable, and sub-licensable right to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with our services and the services of our business clients, including without limitation for promoting and redistributing part or all of our Platform (and derivative works thereof) in any media formats and through any media channels.</li>
                    <li className="additional-text">Confirm that you own or have the necessary licenses, rights, consents, and permissions to publish the content you submit; and that you grant us and our business clients the rights described above.</li>
                    <li className="additional-text">Agree that you will not submit any content that is unlawful, defamatory, infringing, obscene, or otherwise objectionable.</li>
                </ol>
                <div className="h4">Parental Consent for Minor Content</div>
                <div className="additional-text">If you upload, post, or share any content that includes images, videos, or any other identifiable information of a minor/child under the age of 18 ("Minor Content"), you represent and warrant that you are the parent or legal guardian of the minor/child, or that you have obtained explicit consent from the parent or legal guardian to upload, post, or share the Minor Content. By doing so, you grant Get Social Scale permission to use, display, reproduce, distribute, and modify the Minor Content in accordance with these Terms and Conditions.</div>
            </div>
            <div className="terms-container">
                <div className="h3">We are not responsible for viruses and you must not introduce them</div>
                <div className="additional-text">We do not guarantee that our Platform will be secure or free from bugs or viruses.</div>
                <div className="additional-text">You are responsible for configuring your information technology, computer programs, and platform to access our Platform. You should use your own virus protection software.</div>
                <div className="additional-text">You must not misuse our Platform by knowingly introducing viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to our Platform, the server on which our Platform is stored, or any server, computer, or database connected to our Platform. You must not attack our Platform via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you would commit a criminal offense under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities, and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Platform will cease immediately.</div>
            </div>
            <div className="terms-container">
                <div className="h3">Rules about linking to our Platform</div>
                <div className="additional-text">You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</div>
                <div className="additional-text">You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists.</div>
                <div className="additional-text">You must not establish a link to our Platform in any website that is not owned by you.</div>
                <div className="additional-text">Our Platform must not be framed on any other site, nor may you create a link to any part of our Platform other than the home page.</div>
                <div className="additional-text">We reserve the right to withdraw linking permission without notice.</div>
                <div className="additional-text">If you wish to link to or make any use of content on our Platform other than that set out above, please contact {contactEmail}.</div>
            </div>
            <div className="terms-container">
                <div className="h3">Which country’s laws apply to any disputes?</div>
                <div className="additional-text">If you are a consumer, please note that these terms of use, their subject matter, and their formation, are governed by U.S. law, and specifically under the laws of State of North Carolina.</div>
                <div className="additional-text">If you are a business, these terms of use, their subject matter, and their formation (and any non-contractual disputes or claims) are governed by U.S. law. We both agree to the exclusive jurisdiction of the courts of North Carolina.</div>
            </div>
        </div>
    );
};

export default TermsAndConditions;