import React, {useContext, useState, useRef} from "react";
import {GeneralContext} from "../../contexts/GeneralContext";
import {ModalContext} from "../../contexts/ModalContext";
import {language} from "../../shared-components/content/language";
import {Utility} from "../../shared-components/Utility";
import StyledNotification from "../../shared-components/generic/StyledNotification";
import VideoRecorder from "../../shared-components/VideoRecorder";

const UploadMediaModal = () => {
    const {
        appLang,
        setUploadStage,
        setReturnToStage,
        uploadMedia,
        uploadBlob,
        setErrorMsg,
    } = useContext(GeneralContext);
    const {
        uploadMediaModalOpen,
        fromLandingPage,
        setUploadMediaModalOpen,
        setFromLandingPage,
    } = useContext(ModalContext);
    const [isRecorderOpen, setIsRecorderOpen] = useState(false);
    const content = language[appLang];
    const uploadMediaRef = useRef();

    const handleRecordingComplete = (blobFile, fileExt) => {
        uploadBlob(blobFile, fileExt);
        handleClose();
    };

    const handleUploadMedia = (e) => {
        let files;
        if (!!e.target.files && typeof e.target.files === "object") {
            files = Object.values(e.target.files);
        }
        if (!files) {
            return
        }

        const sanitizedFiles = files.filter((file) => {
            return !!file && (file.type.startsWith('image/')) && file.size > 0;
        });
        if (sanitizedFiles.length > 0) {
            uploadMedia(sanitizedFiles);
        }

        handleClose();
    }

    const initiateUpload = () => {
        uploadMediaRef.current.click();
    }


    const handleClose = (sendPermissionDeniedMessage = false) => {
        if (sendPermissionDeniedMessage) {
            setErrorMsg(Utility.capitalize(content.CAMERA_AND_MICROPHONE_PERMISSIONS_NEEDED_TO_RECORD_VIDEO));
        }

        setIsRecorderOpen(false);
        setUploadMediaModalOpen(false);

        if (fromLandingPage) {
            setReturnToStage(1);
            setUploadStage(2);
        }

        setFromLandingPage(false);
    }

    const handleUploadFile = () => {
        initiateUpload();
    }

    const handleOpenVideoRecorder = () => {
        setIsRecorderOpen(true);
    }

    const renderText = () => {
        return (
            <div className="additional-text">
                {Utility.capitalize(content.TAKE_VIDEO_QUESTION)}
            </div>
        )
    }

    return (
        <>
            <StyledNotification
                open={uploadMediaModalOpen}
                onClose={() => handleClose()}
                className="upload-media-modal"
                renderNotification={renderText}
                cancelText={Utility.capitalize(content.UPLOAD_IMAGE)}
                cancelAction={handleUploadFile}
                acceptText={Utility.capitalize(content.TAKE_A_VIDEO)}
                acceptAction={handleOpenVideoRecorder}
            />
            <input
                id="initial-media-upload-input"
                type="file"
                multiple
                accept="image/*"
                style={{display: "none"}}
                onChange={handleUploadMedia}
                ref={uploadMediaRef}
            />
            {isRecorderOpen
                ? <VideoRecorder
                    onRecordingComplete={handleRecordingComplete}
                    onClose={handleClose}
                />
                : null
            }
        </>
    )
}

export default UploadMediaModal;