import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";

const FAQ = () => {
    const {
        appLang,
    } = useContext(GeneralContext);
    const content = language[appLang];

    return (
        <div className="main-container faq with-header">
            <div className="q-a-container">
                <div className="question small-text-regular">
                    {content.Q_WHO_OWNS_THE_CONTENT_I_CREATED}
                </div>
                <div className="answer additional-text">
                    {content.A_THE_CONTENT_IS_OWNED_SENTENCE}
                </div>
            </div>
            <div className="q-a-container">
                <div className="question small-text-regular">
                    {content.Q_HOW_WILL_MY_CONTENT_BE_USED}
                </div>
                <div className="answer additional-text">
                    {content.A_AFTER_GETTING_YOUR_CONSENT_SENTENCE}
                </div>
            </div>
            <div className="q-a-container">
                <div className="question small-text-regular">
                    {content.Q_HOW_DO_YOU_SELECT_THE_WINNERS}
                </div>
                <div className="answer additional-text">
                    {content.A_EVERY_MONTH_WE_KEEP_TRACK_SENTENCE}
                </div>
            </div>
            <div className="q-a-container">
                <div className="question small-text-regular">
                    {content.Q_HOW_DO_I_KNOW_SENTENCE}
                </div>
                <div className="answer additional-text">
                    {content.A_IT_IS_VERY_SIMPLE_SENTENCE}
                </div>
            </div>
        </div>
    );
};

export default FAQ;