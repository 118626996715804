import React, { useState } from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledSelect = (props) => {
    const {
        className,
        options,
        value,
        onChange,
        placeholder,
        header,
        headerTextClass,
        errorMessage,
        hasError,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        onChange(option?.value || "");
        setIsOpen(false);
    };

    return (
        <div className={`styled-select${className ? " " + className : ""}`}>
            {!!header && (
                <div className={`select-header${headerTextClass ? " " + headerTextClass : ""}`}>
                    {header}
                </div>
            )}
            <div
                className={`custom-select ${hasError ? " has-error" : ""}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={`select-display${!!value ? "" : " placeholder"}`}>
                    {value ? options.find(option => option.value === value)?.label : placeholder || "Select an option"}
                </div>
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>
            {isOpen && (
                <div className="select-dropdown">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`select-option ${option.value === value ? "selected" : ""}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
            {hasError && <div className="error-message">{errorMessage}</div>}
        </div>
    );
};

export default StyledSelect;