import React from "react";
import "../appStyle.scss";
import {Utility} from "../shared-components/Utility";

const MediaUpload = (props) => {
    const {
        mediaUrl,
        index,
        fileExt
    } = props;

    return (
        <div className="media-box">
            {Utility.renderMedia(mediaUrl, `Uploaded media ${index + 1}`, fileExt, `media-upload-img-${index}`)}
        </div>
    );
};

export default MediaUpload;