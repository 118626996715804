import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

export function AddBoxIcon(props) {
    const width = props.width || "44";
    const height = props.height || "44";
    const color = props.color;

    return (
        <SvgIcon {...props}>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33333 5.5H36.6667C37.1529 5.5 37.6192 5.69315 37.963 6.03697C38.3068 6.38079 38.5 6.8471 38.5 7.33333V36.6667C38.5 37.1529 38.3068 37.6192 37.963 37.963C37.6192 38.3068 37.1529 38.5 36.6667 38.5H7.33333C6.8471 38.5 6.38079 38.3068 6.03697 37.963C5.69315 37.6192 5.5 37.1529 5.5 36.6667V7.33333C5.5 6.8471 5.69315 6.38079 6.03697 6.03697C6.38079 5.69315 6.8471 5.5 7.33333 5.5ZM20.1667 20.1667H12.8333V23.8333H20.1667V31.1667H23.8333V23.8333H31.1667V20.1667H23.8333V12.8333H20.1667V20.1667Z" fill={color}/>
            </svg>
        </SvgIcon>
    )
}