import React, {useState, useRef, useEffect, useCallback} from 'react';
import {IconButton} from "@mui/material";
import {Circle, DotsThreeCircle, StopCircle, XCircle, CameraRotate} from "@phosphor-icons/react";
import enums from "../enums.json";
import Webcam from "react-webcam";
import {isMobile, isTablet} from "react-device-detect";
import {BeatLoader} from "react-spinners";
import {Utility} from "./Utility";
import "./generic/genericStyle.scss";

const {colors} = enums;

const VideoRecorder = ({onRecordingComplete, onClose}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [currentMimeType, setCurrentMimeType] = useState("video/mp4");
    const [cameraFacing, setCameraFacing] = useState("environment");
    const [hasInitialized, setHasInitialized] = useState(false);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const hasDualCameras = isMobile || isTablet;

    const initializeMediaRecorder = () => {
        const stream = webcamRef.current.video.srcObject;
        const mimeType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm';
        setCurrentMimeType(mimeType);

        mediaRecorderRef.current = new MediaRecorder(stream, {mimeType});
        setHasInitialized(true);
    }

    useEffect(() => {
        if (!hasInitialized && !!webcamRef?.current?.video?.srcObject) {
            initializeMediaRecorder();
        }
    }, [webcamRef, webcamRef?.current?.video?.srcObject]);

    useEffect(() => {
        if (!!mediaRecorderRef?.current) {
            setHasInitialized(true);
        }
    }, [mediaRecorderRef, mediaRecorderRef?.current]);

    const handleDataAvailable = ({data}) => {
        if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    };

    useEffect(() => {
        if (hasInitialized) {
            mediaRecorderRef.current?.addEventListener("dataavailable", handleDataAvailable);
        }
        return () => {
            mediaRecorderRef.current?.removeEventListener("dataavailable", handleDataAvailable);
        };
    }, [hasInitialized]);

    useEffect(() => {
        if (!isRecording && recordedChunks.length) {
            const blob = new Blob(recordedChunks, {type: currentMimeType});
            setRecordedChunks([]);
            onRecordingComplete(blob, currentMimeType === "video/mp4" ? "mp4" : "webm");
        }
    }, [isRecording, recordedChunks, currentMimeType, onRecordingComplete]);

    const handleStartCaptureClick = useCallback(() => {
        if (mediaRecorderRef?.current && mediaRecorderRef.current?.state !== "recording") {
            mediaRecorderRef.current.start();
            setIsRecording(true);
        } else if (!mediaRecorderRef?.current) {
            initializeMediaRecorder();
        }
    }, [mediaRecorderRef, hasInitialized]);

    const handleStopCaptureClick = useCallback(() => {
        if (mediaRecorderRef?.current && mediaRecorderRef.current?.state === "recording") {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    }, [mediaRecorderRef, hasInitialized]);

    const handleToggleCamera = () => {
        if (hasDualCameras) {
            setCameraFacing((prevFacing) => (prevFacing === "environment" ? "user" : "environment"));
            handleStopCaptureClick();
            mediaRecorderRef.current = null;
            setHasInitialized(false);
            Utility.sleep(500).then(() => initializeMediaRecorder());
        }
    };

    const handleCloseVideoRecorder = () => {
        if (webcamRef?.current?.video?.srcObject) {
            webcamRef.current.video.srcObject.getTracks().forEach((track) => track.stop());
        }
        onClose();
    };

    const handlePermissionDenied = (err) => {
        console.log(err);
        onClose(true);
    }

    return (
        <div className="video-recorder">
            <Webcam
                muted
                audio={true}
                ref={webcamRef}
                videoConstraints={hasDualCameras ? {facingMode: cameraFacing} : {}}
                onUserMediaError={handlePermissionDenied}
            />
            <div className="controls">
                <IconButton onClick={handleToggleCamera} className="video-recorder-toggle-camera">
                    <CameraRotate size={24} color={colors.WHITE} weight="fill"/>
                </IconButton>
                {isRecording
                    ? <IconButton className="video-btn" onClick={handleStopCaptureClick}>
                        <StopCircle size={64} color={colors.EMPHASIZED_RED} weight="fill"/>
                    </IconButton>
                    : <IconButton className="video-btn" onClick={hasInitialized ? handleStartCaptureClick : null}>
                        <Circle size={64} color={colors.BASE_RED} weight="fill"/>
                    </IconButton>
                }
            </div>
            <IconButton onClick={handleCloseVideoRecorder} className="video-recorder-close-btn">
                <XCircle size={24} color={colors.WHITE} weight="fill"/>
            </IconButton>
        </div>
    );
};

export default VideoRecorder;