import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";
import {AddBoxIcon} from "../assets/svgIcons/AddBoxIcon";

const StyledUpload = (props) => {
    const {
        children,
        className,
        onClick,
        brandColorOverride,
    } = props;

    return (
        <div
            className={`${className ? className + " " : ""}styled-upload`}
            onClick={onClick}
        >
            <div className="upload-btn">
                <AddBoxIcon
                    sx={{fontSize: 44}}
                    color={brandColorOverride || "#E85B81"}
                />
            </div>
        </div>
    );
};

export default StyledUpload;