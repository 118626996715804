import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {getStorage} from 'firebase/storage';
import settings from '../settings.json';
const {firebaseConfig} = require('../env-configuration.json');

export const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp, `gs://${firebaseConfig.storageBucket}`)
export const auth = getAuth(firebaseApp);
export const func = getFunctions(firebaseApp);
if(!!settings.emulator) {
    connectFunctionsEmulator(func, "localhost", 5001);
}