import React, {useContext} from 'react';
import {GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import StyledUpload from "../shared-components/generic/StyledUpload";
import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import StyledCheckbox from "../shared-components/generic/StyledCheckbox";
import StyledButton from "../shared-components/generic/StyledButton";
import StyledList from "../shared-components/generic/StyledList";
import {ModalContext} from "../contexts/ModalContext";

const KeepSnapping = () => {
    const {
        appLang,
        currentUploads,
        hasAcceptedTerms,
        hasBeenAskedToShareVideo,
        setUploadStage,
        setReturnToStage,
        setCurrentUploads,
        setHasAcceptedTerms,
        goToShareMore,
        secondIncentive,
        // checkUploadsIncludeVideos,
    } = useContext(GeneralContext);
    const {
        setVideoNudgeModalOpen,
        setUploadMediaModalOpen,
        setFromLandingPage
    } = useContext(ModalContext);
    const content = language[appLang];

    const handleDeleteImage = (index) => {
        const updatedCurrentUploads = [...currentUploads];
        const imageToDelete = updatedCurrentUploads[index];
        const urlToRevoke = imageToDelete.tempUrl;

        updatedCurrentUploads.splice(index, 1);
        setCurrentUploads(updatedCurrentUploads);

        URL.revokeObjectURL(urlToRevoke);
    }

    const renderCurrentUploads = () => {
        const mediaToRender = [];
        currentUploads.forEach((uploadObj, index) => {
            mediaToRender.push(
                <div className="media-box" key={`media-upload-${index}`}>
                    {Utility.renderMedia(uploadObj.tempUrl, `Uploaded media ${index + 1}`, uploadObj.metaData.fileExt, "current-upload")}
                    <IconButton onClick={() => handleDeleteImage(index)}>
                        <DeleteForever/>
                    </IconButton>
                </div>
            )
        });

        return mediaToRender;
    }

    const handleInitiateUpload = () => {
        setFromLandingPage(false);
        setUploadMediaModalOpen(true);
    }

    const goToTermsAndConditions = () => {
        setReturnToStage(2);
        setUploadStage(6);
    }

    const getTermsAndConditionsLabel = () => {
        const variableMap = new Map();
        const createTermsAndConditionsLink = () => {
            return (
                <div className="styled-link" onClick={goToTermsAndConditions}>
                    <span>{content.TERMS_AND_CONDITIONS}</span>
                    <div className="link-underline"/>
                </div>
            )
        };
        variableMap.set("termsAndConditions", createTermsAndConditionsLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$), variableMap);
    }

    const handleGoToNext = () => {
        //TODO re-enable check for video - or gamify the process
        const hasVideoUpload = true//checkUploadsIncludeVideos();

        if (!hasVideoUpload && !hasBeenAskedToShareVideo) {
            setVideoNudgeModalOpen(true);
        } else goToShareMore();
    }

    return (
        <div className="main-container keep-snapping with-header">
            <div className="image-gallery">
                {renderCurrentUploads()}
                <StyledUpload
                    onClick={handleInitiateUpload}
                />
            </div>
            <div className="small-text-regular tips-container">
                {!!secondIncentive
                    ? <div className={"variable-sentence"}>{Utility.capitalize(content.DONT_FORGET_WE_PICK_A_WINNER_SENTENCE)}</div>
                    : null
                }
                <div>{Utility.capitalize(content.TIPS)}:</div>
                <StyledList
                    items={[
                        Utility.capitalize(content.LEVERAGE_SHORT_VIDEOS),
                        Utility.capitalize(content.SHOWCASE_THE_VIBES_AND_AMBIANCE),
                        Utility.capitalize(content.MIX_IT_UP_WITH_VARIETY),
                    ]}
                />
            </div>
            <div className="bottom-section">
                <StyledCheckbox
                    className="additional-text"
                    checked={hasAcceptedTerms}
                    onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                    label={getTermsAndConditionsLabel()}
                />
                <StyledButton
                    type="main"
                    onClick={handleGoToNext}
                    isDisabled={!hasAcceptedTerms || currentUploads.length < 1}
                >
                    {Utility.capitalize(content.NEXT)}
                </StyledButton>
            </div>
        </div>
    )
};

export default KeepSnapping;