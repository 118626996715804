// noinspection DuplicatedCode

import enums from '../../enums.json';
const {languages} = enums;

export const language = {
    [languages.ENGLISH]: {
        A_AFTER_GETTING_YOUR_CONSENT_SENTENCE: "A: After getting your consent, we (the business/brand) may select the best content to share with more audiences through our social media. This will help fellow foodies discover our delights!",
        A_EVERY_MONTH_WE_KEEP_TRACK_SENTENCE: "A: Every month, we keep track of the published content’s engagement on social media. If yours get the most likes and re-posts you can redeem free meals at your next visit!",
        A_IT_IS_VERY_SIMPLE_SENTENCE: "A: It's very simple! If you're the winner, we (the business) will email or text you, based on the contact info you shared with us. Then, on your next visit, you can simply show your email or text message to claim your free meal! You can also bring your friends and family here to create content, the more/better you create, the more likely you get more free meals!",
        A_THE_CONTENT_IS_OWNED_SENTENCE: "A: The content is jointly owned by the business/brand and the customer (you). This allows both parties to publish the content on social media and websites, and to share it with friends and family.",
        ADD_TO_HOME_SCREEN: "add to home screen",
        ALL_IMAGES_INELIGIBLE_SENTENCE: "Hmm, it appears that one or more of your uploaded media do not fit our expectations. We recommend you include full or un-eaten dishes, or videos that clearly display the environment.",
        ANONYMOUS: "anonymous",
        ANYTHING_YOU_WANT_TO_SAY: "anything you want to say",
        BRAND_COLORS: "brand colors",
        BRAND_DESCRIPTION: "brand description",
        BRAND_LOGO: "brand logo",
        BRAND_NAME: "brand name",
        BRAND_SETUP: "brand setup",
        BRAND_TONE: "brand tone",
        CAMERA_AND_MICROPHONE_PERMISSIONS_NEEDED_TO_RECORD_VIDEO: "Camera and microphone permissions are required to record video.",
        CAPTURE_AND_SHARE_YOUR_MOMENT: "capture and share your moment",
        CAPTURE_THE_MOMENT: "capture the moment",
        CHOOSE_TOPICS_SENTENCE: "choose the topics for the next campaign's posts",
        COMPLETE_BRAND_SETUP: "complete brand setup",
        CONFIRM_DELETE_POST_SENTENCE: "This will remove the post from any upcoming schedule. This action cannot be undone. Do you want to continue?",
        CONFIRM_GENERATE_TOPICS_SENTENCE: "Generate new topics for the scheduled campaign? (This will override all current topics)",
        CONFIRM_GENERATE_POSTS_SENTENCE: "Generate new posts for each date and topic in this list? (This will remove previously created posts scheduled for this date range)",
        CONFIRM_RESET_CALENDAR_SENTENCE: "This will create an new calendar using your chosen posting schedule, any current topics will be reset to match the new calendar. Proceed?",
        CONFIRM_URL_OVERRIDE: "This will override current brand data and content strategy if saved.",
        CONGRATS_ON_SETTING_UP_YOUR_ACCOUNT: "congrats on setting up your account",
        CONTENT: "content",
        CONTENT_CALENDAR: "content calendar",
        CONTENT_PILLARS: "content pillars",
        CONTENT_STRATEGY: "content strategy",
        CONTENT_VOICE: "content voice",
        CREATE: "create",
        CREATE_APPLICATION: "create application",
        CREATE_CALENDAR: "create calendar",
        CREATE_NEW_BRAND: "create new brand",
        CURATE: "curate",
        DATE: "date",
        DELETE_IMAGE: "delete image",
        DELETE_POST: "delete post",
        DO_NOT_POST_ON_THIS_DAY: "do not post on this day",
        DONE: "done",
        DONT_FORGET_WE_PICK_A_WINNER_SENTENCE: "don't forget, whoever uploads the best video or photo to gets a free meal!",
        EDIT_POSTS_SENTENCE: "create or generate posts for the upcoming schedule",
        EMAIL: "email",
        EMAIL_VALIDATED: "email validated",
        ENCOURAGE_SHARE_VIDEO_STATEMENT: "Could you help others discover their next meal with a quick video? Even a 3-second-video can make a big difference!",
        ENTER_A_RAFFLE_TO_WIN_SWAG: "enter a raffle to win swag",
        ENTER_A_TOPIC: "enter a topic",
        FACEBOOK: "Facebook",
        FAQ: "FAQ",
        FILE_TOO_LARGE: "file too large",
        FIRST_LETS_SET_UP_YOUR_ACCOUNT: "first, let's set up your account",
        FOLLOW_US_ON: "follow us on",
        FRI: "Fri",
        FROM_YOUR_EXISTING_WEBSITE: "from your existing website",
        GENERATE_CONTENT_VOICE_EXAMPLES: "generate content voice examples",
        GET_DISCOUNT_SENTENCE: "get a discount or free item from us",
        GET_ONE_TIME_CODE: "get one-time code",
        GET_STARTED: "get started",
        GET_STARTED_SENTENCE: "To get started, please take about 10 minutes to share more with us about your needs",
        GET_SUGGESTED_TOPICS: "get suggested topics",
        GET_YOUR_REWARD_BY_SHOWING_THIS_SCREEN_TO_US: "show this screen to your server or cashier to claim your reward",
        GOOGLE_MAPS_SEARCH: "google search/maps",
        GOOGLE_REVIEW: "Google Review",
        GO_TO_SIGN_UP: "go to sign up",
        GO_TO_LOGIN: "go to login",
        GOT_IT: "got it",
        HOW_DID_YOU_HEAR_ABOUT_US: "how did you hear about us?",
        I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$: "I accept the $$termsAndConditions$$",
        IF_YOUR_CREATIONS_SENTENCE: "If your creations shine, we will reach out not just for your free meal, but also for future collaboration opportunities!",
        IMAGE: "image",
        IMAGE_OF_SOMEONE_TAKING_A_PICTURE_OF_FOOD: "image of someone taking a picture of food",
        IMAGE_OF_THANK_YOU_SURROUNDED_BY_SOCIAL_MEDIA: "image of a thank you in a square surrounded by social media icons",
        IMAGE_UPLOAD_ERROR_SENTENCE: "There was a problem saving the image. This could be due to a lack of permission from the original source. We suggest uploading from your own file and attempting the save again.",
        IMAGES_REJECTED_TEXT: "Almost there! To help others feel the flavor, could you capture some fresh, tasty dishes in the restaurant? Let’s get those mouths watering!",
        IMPORT_BRAND: "import brand",
        IMPORT_YOUR_BRAND: "import your brand",
        INPUT_YOUR_WEBSITE_URL_HERE: "input your website URL here",
        INSTAGRAM: "Instagram",
        KEEP_SNAPPING: "keep snapping",
        KEEP_SPREADING_SENTENCE: "share your experience on Google and social media",
        KEEP_SPREADING_THE_FOODIE_LOVE_AND_TAG_US_AT_$$HANDLE$$: "share your experience on Google and social media and tag us at $$handle$$",
        LETS_GET_STARTED: "let's get started",
        LEVERAGE_SHORT_VIDEOS: "take short videos",
        LOADING: "loading",
        LOG_IN: "log in",
        LOG_IN_WITH_GOOGLE: "log in with Google",
        LOG_OUT: "log out",
        LOGIN: "login",
        LOGO: "logo",
        LOVE_BEING_IN_THE_SPOTLIGHT_SENTENCE: "Love being in the spotlight? Share your social media handle with us, and get ready to be tagged in all the delicious moments you created with us! Let's spread the joy of good food together!",
        LOVE_OUR_FOOD: "love our food",
        MAILER: "Flyer/printed Ads",
        MIX_IT_UP_WITH_VARIETY: "mix it up with variety",
        MON: "Mon",
        MORE_CASUAL: "more casual",
        MORE_PROFESSIONAL: "more professional",
        NEXT: "next",
        NEXT_TIME: "next time",
        OTHER: "other",
        PASSWORD: "password",
        PHYSICAL_PROXIMITY: "Drove/walked by",
        PLEASE_PROVIDE_YOUR_EMAIL_SENTENCE: "Please provide your email to receive a link to all the fun moments. We will also notify you if you win the raffle drawing!",
        PLEASE_READ_TERMS_SENTENCE: "please read these terms and conditions carefully before using this site",
        PLEASE_USE_CURRENT_MEDIA: "please use media current to this event",
        POST_IMAGE: "post image",
        POSTING_SCHEDULE: "posting schedule",
        PREVIEW: "preview",
        PRIMARY_COLOR: "primary color",
        Q_HOW_DO_I_KNOW_SENTENCE: "Q: How do I know if I am the winner? And how do I redeem it?",
        Q_HOW_DO_YOU_SELECT_THE_WINNERS: "Q: How do you select the winners?",
        Q_HOW_WILL_MY_CONTENT_BE_USED: "Q: How will my content be used?",
        Q_WHO_OWNS_THE_CONTENT_I_CREATED: "Q: Who owns the content I created?",
        QUESTIONS_CHECK_OUT_OUR_$$FAQ$$: "Questions? Check out our $$faq$$",
        REGISTER: "register",
        REGISTRATION_COMPLETED_SENTENCE: "We're thrilled to support you as you spread your brand love! Get ready to use this app to access powerful resources and communicate with us.",
        RELEVANT_HASH_TAGS: "relevant hash tags",
        REMEMBER_ME_ON_THIS_DEVICE: "remember me on this device",
        REQUIRED: "required",
        RESET_CALENDAR: "reset calendar",
        REVIEW_POSTS: "review posts",
        SAT: "Sat",
        SAVE_ALL_AND_GENERATE_NEW_POSTS: "save all and generate new posts",
        SAVE_ALL_POSTS: "save all posts",
        SAVE_CHANGES: "save changes",
        SAVE_CONTENT_STRATEGY: "save content strategy",
        SAVE_CONTENT_CALENDAR: "save content calendar",
        SAVE_POST: "save post",
        SCALE_SOCIAL: "Scale Social",
        SCALE_SOCIAL_DESCRIPTION_SHORT: "your one-stop-shop social media marketing destination",
        SCALE_SOCIAL_LOGO: "scale social logo",
        SCAN_WEBSITE: "scan website",
        SCHEDULE_CONTENT: "schedule content",
        SECONDARY_COLOR: "secondary color",
        SELECT: "select",
        SELECT_DAYS_TO_POST_DURING_THE_WEEK: "select days to post during the week",
        SELECT_THE_CONTENT_VOICE_SENTENCE: "select the tone and voice you want reflected in your posts",
        SHARE: "share",
        SHARE_AND_UPLOAD_YOUR_PHOTOS_OR_VIDEOS: "share and upload your photos or videos",
        SHARE_MORE_WITH_US_OPTIONAL: "share more with us (optional)",
        SHARE_SOCIAL_HANDLE_MONTHLY_WINNER_SENTENCE: "share your social handle, and we'll announce the monthly winner on social media, who will get a free meal as a reward",
        SHARE_SOCIAL_HANDLE_NO_MONTHLY_WINNER_SENTENCE: "share your social handle, and get ready to be tagged in all the delicious moments you created with us",
        SHARE_THE_JOY: "share the joy",
        SHOWCASE_THE_VIBES_AND_AMBIANCE: "capture fresh, mouthwatering dishes",
        SIGN_IN: "sign in",
        SIGN_UP: "sign up",
        SIGN_UP_WITH_EMAIL_AND_PASSWORD: "sign up with email and password",
        SNAP_AND_UPLOAD_SENTENCE: "snap + upload tasty videos or photos",
        SOCIAL_MEDIA: "social media",
        SOCIAL_MEDIA_HANDLE: "social media handle (optional)",
        SOCIAL_MEDIA_SETTINGS: "social media settings",
        STANDARD: "standard",
        START_SHARING: "start sharing",
        SUN: "Sun",
        SWITCH_BRANDS: "switch brands",
        TAKE_A_VIDEO: "take a video",
        TAKE_VIDEO_QUESTION: "Would you like to record a video or upload an image?",
        TAP_AND_HOLD_TO_SAVE_TO_YOUR_PHOTO_ALBUM: "tap + hold to download and re-share",
        TARGET_AUDIENCE: "target audience",
        TERMS_AND_CONDITIONS: "Terms and Conditions",
        THANK_YOU: "thank you",
        THANK_YOU_SENTENCE: "Thanks so much for sharing your foodie content!",
        THANKS_FOR_SHARING_SENTENCE: "Thank you for sharing!",
        THERE_WAS_A_PROBLEM_DOWNLOADING_YOUR_CONTENT_SENTENCE: "There was a problem downloading your content. Please try again.",
        THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE: "There was a problem uploading your content. Please try again.",
        THIS_WILL_HELP_SENTENCE: "This will help fellow foodies discover our delights!",
        THIS_USER_GENERATED_CONTENT_SENTENCE: 'This User-Generated Content (UGC) platform ("Platform") is operated by Scale Social AI, LLC ("We" or "Us"). We are registered in the United States under the laws of North Carolina.',
        THU: "Thu",
        TIK_TOK: "TikTok",
        TIPS: "tips",
        TO_CONTACT_US_PLEASE_EMAIL_$$CONTACT_EMAIL$$: "To contact us, please email $$contactEmail$$",
        TOPIC: "topic",
        TUE: "Tue",
        UNDO_UPDATES: "undo updates",
        UNIQUE_SELLING_POINT: "unique selling point",
        UNNAMED_BRAND: "unnamed brand",
        UPLOAD_IMAGE: "upload image",
        UPLOAD_LOGO: "upload logo",
        URL_COPIED_TO_CLIPBOARD_SENTENCE: "media URL copied, select paste to use in your review",
        VALID_EMAIL_ADDRESS_REQUIRED: "valid email address required",
        VERIFY_EMAIL_ADDRESS: "verify email address",
        VIEW: "view",
        VIEW_MY_SHARES: "view my shares",
        VIEW_OTHERS_MOMENTS_IN_A_LIVE_FEED: "view others' moments in a live feed",
        WANT_TO_TEAM_UP_SENTENCE: "Want to team up in the future? Drop your contact info, and we'll send more fun ways to boost your influence!",
        WED: "Wed",
        WED_LOVE_YOUR_HELP: "We'd love your help!",
        WELCOME_ABOARD: "welcome aboard",
        WELCOME_TO: "welcome to",
        WELCOME_TO_$$EVENT_NAME$$: "welcome to $$eventName$$",
        WELL_SPREAD_LOVE_SENTENCE: "we'll spread your love",
        WINNERS_GET_FREE_MEALS: "winners get free meals",
        WHO_WE_ARE_AND_HOW_TO_CONTACT_US: "who we are and how to contact us",
        WORD_OF_MOUTH: "word of mouth",
        WOULD_YOU_LIKE_YOUR_NAME_TO_SHINE: "would you like your name to shine",
        WRITE_YOUR_POST_HERE: "write your post here",
        YOUR_NAME: "your name",
        YOUR_NAME_OPTIONAL: "your name (optional)",
        YOUR_EMAIL_ADDRESS_USED_FOR_SCALE_SOCIAL: "your email address used for Scale Social",
        YOUR_EMAIL_OPTIONAL: "your email (optional)",
        YOUR_PHONE_NUMBER_OR_EMAIL: "your phone number or email (optional)",
        YOUR_THOUGHTS_OR_COMMENTS_OPTIONAL: "your thoughts or comments (optional)"
    }
}