import './appStyle.scss';
import {Box} from "@mui/material";
import Main from "./components/Main";
import {Suspense, useContext, useEffect} from "react";
import {GeneralContext} from "./contexts/GeneralContext";
import NotFound from "./components/NotFound";
import {ConstantLoaderModal} from "./components/modals/LoaderModal";
import ModalContainer from "./components/modals/ModalContainer";

function App() {
    const {
        brandIsDoingCgc,
        setContentLoading,
        clearTempMedia,
        getBrandData,
    } = useContext(GeneralContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const brandId = urlSearchParams.get('id');

    useEffect(() => {
        getBrandData(brandId).then(() => {
            setContentLoading(false);
        });
        return () => clearTempMedia();
    }, []);

    return (
        <div className="app small-text-regular">
            <Box className="app-container">
                {brandIsDoingCgc
                    ? <Main/>
                    : <NotFound/>
                }
            </Box>
            <Suspense fallback={ConstantLoaderModal}>
                <ModalContainer/>
            </Suspense>
        </div>
    );
}

export default App;
