import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";
import {Box, Modal} from "@mui/material";

const StyledModal = (props) => {
    const {
        open,
        onClose,
        children,
        className
    } = props;

    return (
        <Modal open={open} onClose={onClose} className="styled-modal-container">
            <Box className={`styled-modal ${className}`}>
                {children}
            </Box>
        </Modal>
    );
};

export default StyledModal;